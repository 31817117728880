
import * as Components from "./Willys"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "willys"
}

